import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { SEO, Layout } from '../components'
import styles from './teklif-al.module.css'
import Img from 'gatsby-image'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { Mail, Phone } from '../components/icons'

export default function Iletisim({ data }) {
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [ulasim, setulasim] = useState('')
  const [email, setemail] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    alert(`Welcome ${firstName} ${lastName}!`)
  }

  return (
    <Layout>
      <SEO title="İletişim" description="Timurlar Çatalca Sigorta İletişim Bilgileri, Nasıl Giderim, Çatalca Sigorta Şirketleri, Çatalca Sigorta Acentesini bulabilirsiniz." />

      <div className={['page-content', styles.teklif].join(' ')}>
        <div className="overlaybg">
          <Img fluid={data.homeImage.childImageSharp.fluid} />
        </div>
        <div className="container">
          <h1>İletişim</h1>
          <p>
            Şikayet, öneri, destek ve poliçe konuları için bize yazabilirsiniz.
          </p>
          <ul className="contact d-flex justify-content-between">
            <li>
              <a className={styles.mail} href="mailto:info@timurlarcatalca.com">
                <Mail /> info@timurlarcatalca.com
              </a>
            </li>
            <li>
              <a className={styles.phone} href="tel:(0543) 211 34 99">
                <Phone /> (0543) 211 34 99
              </a>
            </li>
            <li>
              <a className={styles.phone} href="tel:(0212) 789 4186">
                <Phone /> (0212) 789 4186
              </a>
            </li>
          </ul>
          <p className={styles.address}>
            <a href="/">
              {' '}
              Timurlar Sigorta Çatalca Ferhatpaşa Mah. Tahtaköprü Caddesi No:35/A
              Çatalca/İstanbul
            </a>
          </p>
          <div className="row">
            <div className="col-lg-6 pr-lg-5 d-flex justify-content-center flex-column">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1502.3398665528955!2d28.46465200831683!3d41.14151694482191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x49d02775e21f8e57!2sTimurlar%20%C3%87atalca%20Sigorta!5e0!3m2!1str!2str!4v1589782524687!5m2!1str!2str"
                width="100%"
                height="100%"
                id="gmaps"
                name="gmaps"
                title="gmaps"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
              />
            </div>
            <div className="col-lg-6 mb-5 mb-lg-0">
              <form className={styles.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Ad Soyad"
                      name="lastName"
                      autoComplete="lname"
                      value={lastName}
                      onChange={(e) => setlastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="Telefon"
                      value={firstName}
                      onChange={(e) => setfirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Size Nasıl Dönüş Yapalım
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={ulasim}
                        onChange={(e) => setulasim(e.target.value)}
                        label="Age">
                        <MenuItem value="telefon">Telefon</MenuItem>
                        <MenuItem value="whatsapp">Whatsapp</MenuItem>
                        <MenuItem value="mail">Email</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="kvkk">
                      <Checkbox
                        id="kvkk"
                        name="kvkk"
                        value="allowExtraEmails"
                        color="primary"
                      />
                      <a href="#">Kvkk</a> Metnini okudum onaylıyorum.
                    </label>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  className="mt-4">
                  İletişime Geç
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    homeImage: file(name: { eq: "banner" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
